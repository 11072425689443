import httpRequest from '@/services/httpRequest-service.js';

class PhotosService {

    listPhotos(start, limit) {
        return httpRequest.axios.get(`/api/photos?start=${start}&limit=${limit}`);
    }

    listPhotosLocationId(start,limit,locationId) {
        return httpRequest.axios.get(`/api/photos/local/${locationId}?start=${start}&limit=${limit}`);
    }

    listHighlightPhotos() {
        return httpRequest.axios.get(`/api/photos/highlight`);
    }
}

export default new PhotosService();
