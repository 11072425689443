<template>
  <div class="LayoutDefault">
    <header>
      
      <Navbar />

      <div class="content-hero">
        <div class="container">
          <h1>Aplicação de Películas e Adesivos</h1>
          <p
            class="introduction"
          >Instalação de películas e adesivos para proteção solar, privacidade, decoração de móveis e ambientes</p>

          <router-link :to="{ name: 'quotation'}">
            <ButtonDefault            
              rotulo="Faça uma Cotação!"
              btnStyle="primary"
              btnSize="medium"
            />
          </router-link>

        </div>
      </div>
      <!-- content-hero end -->
    </header>
    <main class="LayoutDefault__main">
      <slot />
    </main>

    <Footer />
    
  </div>
</template>

<script>
import ButtonDefault from "@/components/shared/buttons/button-default.vue";
import Navbar from "@/components/shared/menu/navbar.vue";
import Footer from "@/components/shared/footer/footer.vue";

import { goToElement } from "@/mixin/go-to-element-mixin.js";

export default {
  mixins: [goToElement],

  components: {
    ButtonDefault,
    Navbar,
    Footer,
  },

  name: `LayoutDefault`,
  data() {
    return {
      user: null,      
    };
  },
  created() {

    this.$myGlobalCheckCreated.layoutInternal = 'not-created';
    this.$myGlobalCheckCreated.layoutDefault = 'created';

    // Simulate fetching user data.
    setTimeout(() => {
      this.user = { name: `John Doe` };
    }, 2000),
      this.$nextTick(function () {
        // lazy
        //console.warn(this.$scopedSlots.default()[0].context.$refs)
      });
  },

  
};
</script>

<style lang="scss">
@import "@/styles/_variables.scss";

.LayoutDefault {

  header {
    display: flex;
    flex-direction: column;
    height: 100vh;    

      /* 
      SMALL: 768
      MEDIUM: 1366
      fhd: 1920
      QHD: 2560
      4K: 3840
      */

    background-image: 
      linear-gradient(
        rgba(0, 0, 0, 0.38),
        rgba(0, 0, 0, 0.38)
      ), url("/images/img-hero-morumbi-425x900.jpg");    

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (min-width: $screenMedium) {
      background-image: 
        linear-gradient(
          rgba(0, 0, 0, 0.38),
          rgba(0, 0, 0, 0.38)
        ), url("/images/img-hero-morumbi-1366.jpg");
    }

    @media (min-width: 1920px) {
      background-image: 
        linear-gradient(
          rgba(0, 0, 0, 0.38),
          rgba(0, 0, 0, 0.38)
        ), url("/images/img-hero-morumbi-1920.jpg");
      }

    @media (min-width: 2560px) {
      background-image: 
        linear-gradient(
          rgba(0, 0, 0, 0.38),
          rgba(0, 0, 0, 0.38)
        ), url("/images/img-hero-morumbi-2560.jpg");
      }

    @media (min-width: 3840px) {
      background-image: 
        linear-gradient(
          rgba(0, 0, 0, 0.38),
          rgba(0, 0, 0, 0.38)
        ), url("/images/img-hero-morumbi-3840.jpg");
      }
  }

  .content-hero {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    margin-top: -115px;
    text-align: center;
    color: white;

    .introduction {
      margin-bottom: 1.5rem;
      font-size: 1rem;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: $screenLarge) {
        max-width: 665px;
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
      }
    }
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;

    @media (min-width: $screenLarge) {
      margin-bottom: .5rem;
      font-size: 2.75rem;
    }
  }
}

</style>

