import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  // Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";

export const HopperCarousel = {

  components: {
    Hooper,
    Slide,
    HooperNavigation,
    // HooperPagination,
  },
}