<template>
  <div
    v-if="this.$myGlobalCheckCreated.layoutDefault === 'created'"
  >
    <section id="saiba" class="section-content text-center">
      <div class="container">
        <h2>Conheça as Películas e Adesivos</h2>

        <p class="introduction">
          As películas e adesivos possuem praticidade na instalação e manuteção,
          fornecem conforto térmico, privacidade, decora e protege o ambiente
        </p>

        <div class="row">
          <div class="col-lg-4">
            <div class="card card-saiba">
              <div class="card-body">
                <div class="card-illustration">
                  <div class="card-illustration-content bg-color-red-400">
                    <svg class="icon icon-big icon-buildings">
                      <use xlink:href="/images/icon-lib.svg#icon-buildings" />
                    </svg>
                  </div>
                </div>
                <div class="card-title">Lugares para Aplicação</div>
                <div class="card-list">
                  <ul>
                    <li class="card-list-item">Janelas, fachadas e sacadas</li>
                    <li class="card-list-item">Portas e divisórias</li>
                    <li class="card-list-item">Tetos e paredes</li>
                    <li class="card-list-item">Guaritas de condomínio</li>
                    <li class="card-list-item">Vidros e eletrodomésticos</li>
                    <li class="card-list-item">Elevadores e vitrines</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- col end -->

          <div class="col-lg-4">
            <div class="card card-saiba">
              <div class="card-body">
                <div class="card-illustration">
                  <div class="card-illustration-content bg-color-green-400">
                    <svg class="icon icon-big icon-monetization-touch-coin">
                      <use
                        xlink:href="/images/icon-lib.svg#icon-monetization-touch-coin"
                      />
                    </svg>
                  </div>
                </div>
                <div class="card-title">Benefícios</div>
                <div class="card-list">
                  <ul>
                    <li class="card-list-item">
                      Proteção solar e redução de calor
                    </li>
                    <li class="card-list-item">
                      Aumento da resistência dos vidros
                    </li>
                    <li class="card-list-item">Protege objetos do sol</li>
                    <li class="card-list-item">
                      Economia com o ar condicionado
                    </li>
                    <li class="card-list-item">Modernização de fachadas</li>
                    <li class="card-list-item">Facilidade de limpeza</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- col end -->

          <div class="col-lg-4">
            <div class="card card-saiba">
              <div class="card-body">
                <div class="card-illustration">
                  <div class="card-illustration-content bg-color-blue-400">
                    <svg class="icon icon-big icon-material-isolation">
                      <use
                        xlink:href="/images/icon-lib.svg#icon-material-isolation"
                      />
                    </svg>
                  </div>
                </div>
                <div class="card-title">Tipos de Películas</div>
                <div class="card-list">
                  <ul>
                    <li class="card-list-item">
                      Películas jateadas, espelhadas, escuras
                    </li>
                    <li class="card-list-item">Películas de proteção solar</li>
                    <li class="card-list-item">
                      Películas transparentes com rejeição UV
                    </li>
                    <li class="card-list-item">Películas decorativas</li>
                    <li class="card-list-item">
                      Películas de Segurança ante vandalismo
                    </li>
                    <li class="card-list-item">Películas nano híbridos</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->

        <div class="cue" @click="goto('peliculas-instaladas', 'center')">
          <svg class="icon icon-medium">
            <use xlink:href="/images/icon-lib.svg#icon-arrow" />
          </svg>
        </div>
      </div>
      <!-- container end -->
    </section>

    <section
      id="peliculas-instaladas"
      ref="peliculas-instaladas"
      class="section-content"
    >
      <div class="container text-center">
        <h2>Confira alguns Trabalhos</h2>

        <p class="introduction">
          A MF aplica películas e adesivos em diversos locais de acordo com a
          sua necessidade
        </p>
      </div>

      <div class="peliculas-instaladas-content">
        <div class="container" v-if="pageError">
          <PageAlert imgName="img-erro.svg">
            Houve um <strong>erro inesperado </strong> ao carregar as fotos.
            Tente novamente mais tarde.
          </PageAlert>
        </div>

        <div class="hooper-work-container" v-if="photos.length && !pageError">
          <CoolLightBox
            v-if="photos.length"
            :items="putPhotoExtention(photos)"
            :index="index"
            :useZoomBar="true"
            @close="index = null"
          ></CoolLightBox>

          <div class="container-fluid">
            <!-- <hooper :itemsToShow="1" :centerMode="true" pagination="no"> -->
            <hooper :settings="hooperSettingsFotos">
              <slide v-for="(photo, photoIndex) in photos" :key="photoIndex">
                <div @click="index = photoIndex">
                  <Photo
                    :src="photo.thumb"
                    :title="photo.title"
                    :description="photo.description"
                    :idLocation="photo.idLocation"
                    size="medium"
                  />
                </div>
              </slide>

              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>

            <div class="cue" @click="goto('cotacao', 'center')">
              <svg class="icon icon-medium">
                <use xlink:href="/images/icon-lib.svg#icon-arrow" />
              </svg>
            </div>
          </div>
          <!-- container end -->
        </div>
        <div
          class="section-action text-center"
          v-if="photos.length && !pageError"
        >
          Quer ver mais fotos? Acesse&nbsp;
          <router-link :to="{ name: 'work' }">Nossos Trabalhos.</router-link>
        </div>
      </div>
    </section>

    <section id="cotacao" ref="cotacao" class="section-content text-center">
      <div class="container">
        <h2>Faça uma Cotação</h2>

        <p class="introduction">
          Fale com nossos especialistas e solicite um orçamento sem compromisso
          para aplicar películas e adesivos
        </p>

        <QuotationFormContact />
      </div>
      <!-- container end -->
    </section>
  </div>
</template>

<script>
import PhotosService from "@/services/photos/photos-service.js";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import QuotationFormContact from "@/components/cotacao/quotation-form-contact.vue";
// import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
// import "hooper/dist/hooper.css";

import { photoExtention } from "@/mixin/photo-extention.js";
import { goToElement } from "@/mixin/go-to-element-mixin.js";
import { HopperCarousel } from "@/mixin/hooper-carousel-mixin.js";

const CoolLightBox = () =>
  import(/* webpackChunkName: "CoolLightBox" */ "vue-cool-lightbox");
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

const PageAlert = () =>
  import(
    /* webpackChunkName: "PageAlert" */ "@/components/shared/alert/page-alert.vue"
  );

export default {
  name: `Home`,
  mixins: [goToElement, photoExtention, HopperCarousel],

  components: {
    // Hooper,
    // Slide,
    // HooperNavigation,
    QuotationFormContact,
    CoolLightBox,
    Photo,
    PageAlert,
  },

  data: function () {
    return {
      photos: [],
      index: null,
      pageError: false,

      hooperSettingsFotos: {
        itemsToShow: 1.1,
        centerMode: true,
        //initialSlide: 2,
        wheelControl: false,
        infiniteScroll: true,
        transition: 600,
        breakpoints: {
          768: {
            itemsToShow: 2,
            centerMode: false,
          },
          1200: {
            itemsToShow: 3,
            itemsToSlide: 3,
            centerMode: false,
          },
          1921: {
            itemsToShow: 4,
            itemsToSlide: 3,
            centerMode: false,
          },
          2750: {
            itemsToShow: 5,
            itemsToSlide: 3,
            centerMode: false,
          },
        },
      },
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutDefault);
  },

  mounted() {
    // Pegar as fotos e identificar o tamanho do card-illustration-content
    // setar o valor do card-illustration-content -  transform: translateY(20%);

    if (this.$myGlobalCheckCreated.layoutDefault === "created") {
      this.listHighlightPhotos();
    }
  },

  methods: {
    listHighlightPhotos() {
      let self = this;

      PhotosService.listHighlightPhotos()
        .then(function (response) {
          self.photos = response.data.map((item) => {
            return {
              title: item.title,
              description: item.description,
              idLocation: item.id_location,
              src: item.patch + item.name,
              thumb: item.patch + "small/" + item.name,
            };
          });
          self.pageError = false;
        })
        .catch(function (error) {
          self.pageError = true;
          console.log(error);
        });
    },

    goto(refName, position) {
      var element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth", block: position });
    },

    photoOver(event) {
      console.log(event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/card.scss";

h2 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;

  @media (min-width: $screenLarge) {
    font-size: 2rem;
  }
}

.introduction {
  color: $secondary-text-color;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;

  @media (min-width: $screenLarge) {
    max-width: 770px;
  }
}

#saiba {
  background-color: #fafafa;
}

.card-saiba {
  max-width: 350px;
  min-height: 376px;
  margin: auto;
  margin-bottom: 15px;

  .card-title {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .card-list {
    font-size: $BodyText2;
    font-weight: 300;
    line-height: 24px;
  }
  .card-illustration {
    margin: 2rem 0 1.5rem;
  }

  .card-illustration-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 88px;
    margin: auto;
    border-radius: 50%;
    color: #fff;
  }
}

#peliculas-instaladas {
  background-color: #fff;
}

.peliculas-instaladas-content {
  min-height: 406px;
  @media (min-width: $screenLarge) {
    min-height: 508px;
  }
}
.section-content {
  position: relative;
  padding: 2rem 0 2rem 0;

  @media (min-width: $screenLarge) {
    padding: 4rem 0 4.5rem 0;
  }
}

.section-action {
  margin-bottom: 1rem;
}

.container-fluid {
  padding: 0;
}

#cotacao {
  background-color: #fafafa;

  .introduction {
    max-width: 600px;
  }
}

.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  //transition: all .3s;
}

/* Carousel */

/* .hooper-work-container {
  height: 468px;
} */

.hooper {
  height: auto;
  margin-bottom: 1rem;

  /*   @media (min-width: 1487px) {
    height: 600px;
  }
 */
}

/*     .hooper-slide.is-current {
            transform: scale(1.05);
        } */

::v-deep .hooper-slide {
  /* background-color: #62caaa; */

  //max-width: 30%;

  padding: 0 2px 2px 2px;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #fff;
        border: 2px solid #fff;
        font-size: 30px; */
  border-radius: 10px;
}

.hooper {
  &:focus {
    outline: none;
  }
}

::v-deep .hooper-list:focus {
  outline: none;
}
::v-deep .hooper-track:focus {
  outline: none;
}

.hooper-list:focus {
  outline: none;
}

.hooper-navigation {
  @media (max-width: $screenLarge) {
    display: none;
  }
}

::v-deep .hooper-navigation button {
  width: 42px;
  height: 38px;
  padding: 0;
  color: #fff;
  background-color: rgba(30, 30, 30, 0.6);
  transform: translateY(-65%);
  transition: 0.3s all;
}

::v-deep .hooper-navigation button:hover {
  color: $accent-color;
  background-color: rgba(30, 30, 30, 0.9);
}

::v-deep .hooper-navigation button:focus {
  outline: none;
}

::v-deep .hooper-navigation .icon {
  vertical-align: middle;
}

::v-deep .hooper-navigation .hooper-prev {
  left: 15px;
}

::v-deep .hooper-navigation .hooper-next {
  right: 15px;
}

.cue {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  color: lighten($secondary-text-color, 35%);
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
    color: $accent-color;
  }

  @media (min-width: $screenLarge) {
    transform: translate(-50%, -100%);
  }

  svg {
    vertical-align: bottom;
  }
}
</style>
